import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import BasicPageTemplate from '../components/BasicPageTemplate'
import { HTMLContent } from '../components/ContentLegacy'

const BasicPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BasicPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        notice={{title:null, message:null}}
      />
    </Layout>
  )
}

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicPage

export const aboutPageQuery = graphql`
  query BasicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
